import { ConfigurableCartItemFragment } from '@graphcommerce/magento-product-configurable/ConfigurableCartItem/ConfigurableCartItem.gql'

type CartItemOptionsListProps = Partial<ConfigurableCartItemFragment>

/** @deprecated */
export function OptionsList(props: CartItemOptionsListProps) {
  const { configurable_options, configurable_customizable } = props

  return (
    <div className='w-full' style={{ gridArea: 'itemOptions' }}>
      {configurable_options &&
        configurable_options.map((option) => (
          <div key={option?.configurable_product_option_uid}>
            <span className='Type-Large-Regular align-top'>{option?.option_label}: </span>
            <span className='Type-Large-Regular ml-[6px] align-top text-60-grey'>
              {option?.value_label}
            </span>
          </div>
        ))}
      {configurable_customizable &&
        configurable_customizable.map((option) => {
          const value = option?.values.at(0)?.value
          const label = option?.values.at(0)?.label
          return (
            <div key={option?.customizable_option_uid}>
              <span className='Type-Large-Bold align-top'>{option?.label}: </span>
              <span className='Type-Large-Regular align-top'>
                {value?.indexOf('#') === 0 ? (
                  <div className='ml-[6px] inline-flex items-center justify-center'>
                    <div
                      className='inline-block h-[12px] w-[32px] rounded-[3px] border-tight-black align-middle'
                      style={{ backgroundColor: value }}
                    />
                  </div>
                ) : (
                  label || value
                )}
              </span>
            </div>
          )
        })}
    </div>
  )
}
